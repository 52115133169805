import { Dispatch } from "redux";

import { settingsObjectApi } from "./api";
import { settingsObjectActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { IConstControlInBuilding } from "./types";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getConstrControlAccountsForObject =
  (objectId: string, type: keyof IConstControlInBuilding | null) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = generateStorageKey({ type: type!, objectId });
    settingsObjectApi
      .getConstrControlAccounts(objectId, type)
      .then(({ data }) => {
        dispatch(settingsObjectActions.setConstrControlAccounts({ key, data: data.results }));
      })
      .finally(() => {});
  };
