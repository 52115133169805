import cn from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import AddTaskByBuilding from "./components/AddTaskByBuilding/AddTaskByBuilding";
import ModalAddTask from "./components/ModalAddTask/ModalAddTask";
import ModalEditTask from "./components/ModalEditTask/ModalEditTask";
import ModalTaskExisting from "./components/ModalTaskExisting/ModalTaskExisting";
import ModalViewTask from "./components/ModalViewTask/ModalViewTask";
import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ModalContainer from "components/UI/_TODO/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import NavBar from "components/UI/_TODO/WorkOrMaterialsModals/components/NavBar/NavBar";

import { approveTask } from "../../model/thunks";
import { TasksModalHead } from "../TasksModalV2/ui/TasksModalHead/TasksModalHead";
import { useTypedSelector } from "app/store/typedUseSelector";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";
import EmptyPlaceholder from "shared/ui/layout/EmptyPlaceholder/EmptyPlaceholder";
import SliderModal from "shared/ui/modal/SliderModal/SliderModal";

import { MAIN_MODAL_TAB, TASK_MODAL_TYPES, TASK_STATUSES } from "../../constants";
import { IIdAndName } from "types/idAndName";

import { useGetTask } from "../../hooks/useGetTask";
import { useTasksModal } from "../../hooks/useTasksModal";
import { useUpdateTask } from "./../../hooks/useUpdateTask";

import getShortFullName from "utils/formatters/getShortFullName";

import tasksAll from "images/menu-icons/tasksAll.svg";

import styles from "./TasksModal.module.scss";

const taskStatusColors: Record<string, string> = {
  completed: "#A26DD0",
  closed: "#47D6D1",
  any: "#4FB1EB",
  expired: "#EB4F8B",
};

export interface ITasksModalPops {
  presetBuildingId: number;
}

const TasksModal: React.FC<ITasksModalPops> = ({ presetBuildingId }) => {
  const dispatch = useDispatch();
  const addFormRef = useRef<HTMLFormElement>(null);
  const editFormRef = useRef<HTMLFormElement>(null);

  const [activeTab, setActiveTab] = useState<string>(MAIN_MODAL_TAB);
  const { id: myId } = useTypedSelector((state) => state.auth.user);

  const { onClose, type, isOpen, taskId, changeModalType, deleteFilesOnCancel, placementType } = useTasksModal();
  const { certainTask, isCertainTaskLoading } = useGetTask(taskId, isOpen);

  useEffect(() => {
    setActiveTab(MAIN_MODAL_TAB);
  }, [isOpen]);

  const defaultLocalBuildingState = {
    id: presetBuildingId || 0,
    name: "",
  };

  const [localBuilding, setLocalBuilding] = useState<IIdAndName>(defaultLocalBuildingState);
  useEffect(() => {
    setLocalBuilding((prev) => ({ ...prev, id: presetBuildingId }));
  }, [presetBuildingId]);

  const isDeletedTask = useMemo(
    () => type !== TASK_MODAL_TYPES.ADD && !isCertainTaskLoading && !certainTask,
    [certainTask, isCertainTaskLoading, type]
  );
  const isLoading = useMemo(() => isCertainTaskLoading && type !== TASK_MODAL_TYPES.ADD, [isCertainTaskLoading, type]);

  const handleSetLocalBuilding = useCallback(
    (newBuilding: IIdAndName) => setLocalBuilding(newBuilding),
    [setLocalBuilding]
  );

  const handleClose = useCallback(() => {
    if (onClose) onClose();
    setActiveTab(MAIN_MODAL_TAB);
    !presetBuildingId && setLocalBuilding(defaultLocalBuildingState);
    //addFormRef.current && addFormRef.current.dispatchEvent(new Event("reset", { cancelable: true, bubbles: true }));
    editFormRef.current && editFormRef.current.dispatchEvent(new Event("reset", { cancelable: true, bubbles: true }));
  }, [onClose, addFormRef.current, editFormRef.current, presetBuildingId]);

  const handleCreateButton = useCallback(() => {
    if (!addFormRef.current) return;
    addFormRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  }, [addFormRef.current]);

  const handleSaveButton = useCallback(() => {
    if (!editFormRef.current) return;
    editFormRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  }, [editFormRef.current]);

  const handleEditButton = useCallback(() => changeModalType(TASK_MODAL_TYPES.EDIT), []);

  const handleCancelButton = useCallback(() => {
    if (type === TASK_MODAL_TYPES.EDIT) {
      changeModalType(TASK_MODAL_TYPES.VIEW);
      deleteFilesOnCancel();
    } else handleClose();
  }, [type]);
  /* @ts-ignore */
  const { updateTaskStatusDirectly } = useUpdateTask({ taskId: certainTask?.id, placementType });

  const isMeExecutor = +certainTask?.executor === +myId;
  const isMeReviewer = certainTask?.viewers.some((id) => +id === +myId);
  const isMeCreator = +certainTask?.creator === +myId;

  const approvers = certainTask?.approves_data;

  const showApproveButton = useMemo(() => {
    return approvers?.some((approver) => +approver.approver.id === +myId && approver.status !== "approved");
  }, [approvers, myId]);

  const approveId = useMemo(() => {
    return approvers?.find((approver) => +approver.approver.id === +myId)?.id;
  }, [approvers, myId]);

  const hideStatusBtns = useMemo(() => {
    return approvers?.some((el) => el.status !== "approved");
  }, [approvers]);

  const showStartWorkButton =
    isMeExecutor && certainTask?.status === "to_do" && type === TASK_MODAL_TYPES.VIEW && !hideStatusBtns;
  const showSendToReviewButton =
    isMeExecutor && certainTask?.status === "in_work" && type === TASK_MODAL_TYPES.VIEW && !hideStatusBtns;
  const showAcceptButton =
    (isMeCreator || isMeReviewer) &&
    certainTask?.status === "completed" &&
    type === TASK_MODAL_TYPES.VIEW &&
    !hideStatusBtns;
  const showToReworkButton =
    (isMeCreator || isMeReviewer) &&
    certainTask?.status === "completed" &&
    type === TASK_MODAL_TYPES.VIEW &&
    !hideStatusBtns;
  const showEditButton =
    (isMeExecutor || isMeReviewer || isMeCreator) &&
    (certainTask?.status === "to_do" || certainTask?.status === "in_work" || certainTask?.status === "expired") &&
    type === TASK_MODAL_TYPES.VIEW;

  const isCancelButtonShown = type === TASK_MODAL_TYPES.ADD || type === TASK_MODAL_TYPES.EDIT;
  const isProjectsListShown = type === TASK_MODAL_TYPES.ADD && localBuilding.id === 0;

  const showAnyButtonsDependedOnStatus =
    showStartWorkButton || showSendToReviewButton || showAcceptButton || showEditButton || showApproveButton;
  const isCommonControlsShown =
    localBuilding.id !== 0 || type !== TASK_MODAL_TYPES.ADD || showAnyButtonsDependedOnStatus;

  if (isLoading) {
    return (
      <SliderModal isOpen={isOpen} closeHandler={handleClose} className={styles.modal}>
        <Spinner />
      </SliderModal>
    );
  }

  if (isDeletedTask) {
    return (
      <SliderModal isOpen={isOpen} closeHandler={handleClose} className={styles.modal}>
        <EmptyPlaceholder text="Задача была удалена" img={tasksAll} />
      </SliderModal>
    );
  }

  if (type === TASK_MODAL_TYPES.ADD) {
    return (
      <SliderModal isOpen={isOpen} closeHandler={handleClose} className={styles.modal}>
        <TasksModalHead.New activeTab={activeTab} setActiveTab={setActiveTab} showTabs={!isProjectsListShown} />
        {type === TASK_MODAL_TYPES.ADD && (
          <>
            <AddTaskByBuilding setBuilding={handleSetLocalBuilding} isHidden={!isProjectsListShown} isOpen={isOpen} />
            {!isProjectsListShown && (
              <>
                <ModalAddTask
                  formRef={addFormRef}
                  activeTab={activeTab}
                  presetBuildingId={localBuilding.id}
                  handleCloseModal={handleClose}
                />
                <BottomControls isExists={isCommonControlsShown} isDoubleBtns>
                  <ButtonBase secondary onClick={handleCancelButton}>
                    Отменить
                  </ButtonBase>
                  <ButtonBase primary onClick={handleCreateButton}>
                    <span>Создать</span>
                  </ButtonBase>
                </BottomControls>
              </>
            )}
          </>
        )}
      </SliderModal>
    );
  }

  return (
    <ModalTaskExisting
      formRef={editFormRef}
      handleClose={handleClose}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      handleCancelButton={handleCancelButton}
      handleSaveButton={handleSaveButton}
    />
  );

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={handleClose}
      name={/* type === TASK_MODAL_TYPES.ADD ? "Новая задача" :  */ certainTask?.name}
      customHeadline={
        <></>
        /* <div className={styles.subHeader}>
          {!!certainTask && (
            <div className={styles.statusBar}>
              <div className={styles.creatorName}>
                Создал {getShortFullName(certainTask?.creator_user)}
                <br />
                {moment(certainTask?.created_at).format("DD/MM/YYYY")}
              </div>
              {certainTask?.status && (
                <div
                  style={{ background: taskStatusColors[certainTask?.status] ?? taskStatusColors.any }}
                  className={styles.status}
                >
                  {TASK_STATUSES[certainTask.status]}
                </div>
              )}
            </div>
          )}
          {isCommonControlsShown && (
            <TabBarNotLinks
              isOnlyTabsView
              tabs={TASKS_MODAL_TABS(type === TASK_MODAL_TYPES.VIEW)}
              activeId={activeTab}
              onClick={setActiveTab}
              className={styles.tabs}
              disabled={undefined}
            />
          )}
        </div> */
      }
    >
      {/* {type === TASK_MODAL_TYPES.ADD && (
        <>
          <AddTaskByBuilding setBuilding={handleSetLocalBuilding} isHidden={!isProjectsListShown} isOpen={isOpen} />
          {!isProjectsListShown && (
            <ModalAddTask
              formRef={addFormRef}
              activeTab={activeTab}
              presetBuildingId={localBuilding.id}
              handleCloseModal={handleClose}
            />
          )}
        </>
      )} */}
      {/*  {type === TASK_MODAL_TYPES.VIEW && <ModalViewTask activeTab={activeTab} />} */}
      {type === TASK_MODAL_TYPES.EDIT && (
        <ModalEditTask
          formRef={editFormRef}
          activeTab={activeTab}
          handleCloseModal={handleClose}
          onSave={() => setActiveTab(MAIN_MODAL_TAB)}
          handleCancelButton={handleCancelButton}
          handleSaveButton={handleSaveButton}
        />
      )}
      <BottomControls isExists={isCommonControlsShown} isDoubleBtns>
        {isCancelButtonShown && (
          <ButtonBase secondary onClick={handleCancelButton}>
            Отменить
          </ButtonBase>
        )}

        {/* {showEditButton && (
          <ButtonBase primary onClick={handleEditButton}>
            Редактировать
          </ButtonBase>
        )}

        {showToReworkButton && (
          <ButtonBase secondary onClick={() => updateTaskStatusDirectly("to_do")}>
            На доработку
          </ButtonBase>
        )}
        {showAcceptButton && (
          <ButtonBase primary onClick={() => updateTaskStatusDirectly("closed")}>
            Принять задачу
          </ButtonBase>
        )}
        {showStartWorkButton && (
          <ButtonBase primary onClick={() => updateTaskStatusDirectly("in_work")}>
            Взять в работу
          </ButtonBase>
        )}
        {showSendToReviewButton && (
          <ButtonBase primary onClick={() => updateTaskStatusDirectly("completed")}>
            Отправить на проверку
          </ButtonBase>
        )}
        {showApproveButton && (
          <ButtonBase primary onClick={() => dispatch(approveTask(taskId, approveId!))}>
            Согласовать
          </ButtonBase>
        )}

        {type === TASK_MODAL_TYPES.EDIT && (
          <ButtonBase primary onClick={handleSaveButton}>
            Сохранить
          </ButtonBase>
        )} */}
      </BottomControls>
    </ModalContainer>
  );
};

export default TasksModal;
