import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";

import BottomControls from "components/UI/_TODO/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import { IupdateTask } from "components/pages/Tasks/model/types";

import TaskComentsTab from "../TaskComentsTab/TaskComentsTab";
import ModalEditTaskExtraFields from "./ModalEditTaskExtraFields";
import ModalEditTaskMainFields from "./ModalEditTaskMainFields";
import { useTypedSelector } from "app/store/typedUseSelector";
import { finalFormPersister } from "shared/lib/formUtils/finalFormPersist";
import ButtonBase from "shared/ui/controls/ButtonBase";
import TabBarNotLinks from "shared/ui/controls/TabBar/TabBarNotLinks";

import {
  ADD_TASK_INITIALS,
  COMMENTS_MODAL_TAB,
  EXTRA_MODAL_TAB,
  MAIN_MODAL_TAB,
  TASK_MODAL_TYPES,
  getTaskModalTabs,
} from "../../../../constants";

import { useTasksModal } from "../../../../hooks/useTasksModal";
import { useUpdateTask } from "../../../../hooks/useUpdateTask";

import styles from "../ModalAddTask/ModalAddTask.module.scss";

export interface IModalEditTaskProps {
  formRef: React.RefObject<HTMLFormElement>;
  activeTab: string;
  handleCloseModal: () => void;
  onSave: () => void;
  //TODO
  setActiveTab?: any;
  handleCancelButton: any;
  handleSaveButton: any;
}

const ModalEditTask: React.FC<IModalEditTaskProps> = ({
  formRef,
  activeTab,
  handleCloseModal,
  onSave,
  setActiveTab,
  handleCancelButton,
  handleSaveButton,
}) => {
  const { placementType, listType, list_id, taskId, submission } = useTasksModal();
  const {
    onSubmit,
    building,
    section_id,
    setSectionId,
    executor,
    setExecutor,
    viewers,
    setViewers,
    certainTask,
    isTaskStatusEditable,
  } = useUpdateTask({ taskId, placementType, listType, list_id, onSuccess: onSave });

  const [requestClose, setRequestClose] = useState<boolean>(false);

  useEffect(() => {
    if (!submission?.isSubmitted && submission?.taskId !== -1 && requestClose) handleCloseModal();
  }, [submission, requestClose]);

  const invalidateKey = useTypedSelector((state) => state.tasks.invalidateKey);

  const { persistDecorator, clear } = finalFormPersister<IupdateTask>({
    name: `form_editTask_${taskId}`,
  });

  return (
    <Form
      key={invalidateKey}
      onSubmit={onSubmit}
      decorators={[persistDecorator]}
      initialValues={(certainTask as unknown as IupdateTask) || (ADD_TASK_INITIALS as unknown as IupdateTask)}
      render={({ form, handleSubmit }) => (
        <form
          className={styles.form}
          ref={formRef}
          onSubmit={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (form.getState().valid) {
              clear();
              await handleSubmit();
              form.restart();
              setRequestClose(true);
            } else {
              message.warn("Заполните обязательные поля формы");
            }
          }}
        >
          <TabBarNotLinks
            isOnlyTabsView
            tabs={getTaskModalTabs(TASK_MODAL_TYPES.EDIT)}
            activeId={activeTab}
            onClick={setActiveTab}
            className={styles.tabs}
          />
          <ModalEditTaskMainFields
            building={building}
            section_id={section_id}
            setSectionId={setSectionId}
            isVisible={activeTab === MAIN_MODAL_TAB}
            executor={executor}
            setExecutor={setExecutor}
            viewers={viewers}
            setViewers={setViewers}
            isTaskStatusEditable={isTaskStatusEditable}
          />
          <ModalEditTaskExtraFields certainTask={certainTask as any} isVisible={activeTab === EXTRA_MODAL_TAB} />
          {activeTab === COMMENTS_MODAL_TAB && (
            <div className={styles.root}>
              <TaskComentsTab
                taskId={certainTask?.id!}
                comments={certainTask?.task_comments!}
                task={certainTask as any}
              />
            </div>
          )}
          <BottomControls isDoubleBtns isExists>
            <ButtonBase secondary onClick={handleCancelButton}>
              Отменить
            </ButtonBase>
            <ButtonBase primary onClick={handleSaveButton}>
              Сохранить
            </ButtonBase>
          </BottomControls>
        </form>
      )}
    />
  );
};

export default ModalEditTask;
