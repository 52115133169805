import { message } from "antd";
import { Dispatch } from "redux";

import { axesApi } from "./api";
import { axesActions } from "./axesSlice";
import { RootState } from "app/store/rootReducer";

import { DEFAULT_CREATE_AXES_PARAMS } from "../constants";
import { ICreateAxesArg, ICreateAxis, IEditAxesProps } from "./types";

import { axesUtils } from "../utils";
import { errorCatcher } from "utils/helpers/errorCatcher";
import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const createAxes = (buildingId: string, data: ICreateAxesArg) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ type: "create", buildingId });
  //axesActions.setIsLoading({ key, status: true })
  //data
  axesApi
    .create(buildingId, data)
    .then((resp) => {
      console.log(1212, data.axis);
      const axisPromises = data.axis?.map((el) => axesApi.createAxes(buildingId, el, resp.data.id));
      Promise.all(axisPromises ?? []).then(() => {
        message.success("Сохранено");
        dispatch(axesActions.invalidateKey());
      });
    })
    .catch(errorCatcher)
    .finally(() => {
      axesActions.setIsLoading({ key, status: false });
    });
};

export const getAxes =
  (buildingId: string, planOrFact: "plan" | "fact", workOrGroup: "work" | "group", id: number) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const paramKey = `${planOrFact}_${workOrGroup}` as "fact_group";
    const key = generateStorageKey({ [paramKey]: id });
    if (!getState().axesSlice.axes[id]) {
      dispatch(axesActions.setIsLoading({ key, status: true }));
    }
    axesApi
      .getFacts(buildingId, { [paramKey]: id })
      .then(({ data }) => {
        dispatch(axesActions.setAxes({ axes: data.results, key }));
      })
      .finally(() => {
        dispatch(axesActions.setIsLoading({ key, status: false }));
      });
  };

export const editAxes =
  ({ buildingId, id, data, editedItems, deletedItems, planOrFact, workOrGroup, itemsWithEqualMarks }: IEditAxesProps) =>
  (dispatch: Dispatch) => {
    const paramKey = `${planOrFact}_${workOrGroup}` as "fact_group";
    const pendingKey = generateStorageKey({ [paramKey]: id, type: "pending" });
    const patchData = { drawing: data.drawing, drawing_set: data.drawing_set };
    const newItemsRequests =
      data.axis.filter((el) => el.isNew).map((el) => axesApi.createAxes(buildingId, el, id)) ?? [];

    const editedRequests =
      axesUtils
        .prepareAxisForEdit({ axis: data.axis, editedItems, itemsWithEqualMarks })
        .map((el) => axesApi.editItem(buildingId, id, el.id!, el)) ?? [];

    const deleteRequests = deletedItems.map((el) => axesApi.deleteItem(buildingId, id, el));

    const concatedReqs = [...newItemsRequests, ...editedRequests, ...deleteRequests];

    if (!concatedReqs?.length) {
      return;
    }

    dispatch(axesActions.setIsLoading({ key: pendingKey, status: true }));
    Promise.allSettled(concatedReqs)
      .then(() => {
        message.success("Сохранено");
        dispatch(axesActions.invalidateKey());
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(axesActions.setIsLoading({ key: pendingKey, status: false }));
      });
  };
